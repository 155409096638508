import React, { PureComponent } from 'react'
import { createComponent, ThemeProvider, withTheme } from '@ycos/fela'
import { withRouter } from 'next/router'
import Helmet from 'react-helmet'
import getConfig from 'next/config'
import BaseLayout from '@ycos/nextjs/src/BaseLayout'
import { withOptimizely, trackPage as trackPageOptimizely } from '@ynap/optimizely-utils'
import { trackPageVisit } from '../src/analytics-track'
import { getTheme, setThemeDirection } from '../helpers/themes'
import waitForSifu from '../helpers/sifuHelper'
import { getCampanyPagesLdJson } from '../src/utils/getLdJson'

const Container = createComponent(
  'Container',
  ({ theme }) => ({
    padding: `${5 * theme.spacingMultiplier}px 0 ${8 * theme.spacingMultiplier}px`,
    'screen-large': {
      paddingTop: `${4 * theme.spacingMultiplier}px`
    }
  }),
  'div'
)

const Title = createComponent(
  'Title',
  () => ({
    fontSize: 0,
    height: '1px',
    left: 'auto',
    position: 'absolute',
    width: '1px'
  }),
  'h1'
)

class HomepageLayout extends PureComponent {
  componentDidMount() {
    const { locale, siteMerchandisingItems, publicRuntimeConfig, analyticsAttributes, optimizely } = this.props
    const { pageName = 'homepage', pageType, subcategory1, gender } = analyticsAttributes || {}
    const categoryData = {
      pageType: pageType || pageName,
      primaryCategory: pageType || pageName,
      subcategory1: subcategory1 || pageName,
      subcategory2: 'standard'
    }

    new Promise((resolve) => {
      waitForSifu(resolve, 50, 300)
    }).then(() => {
      trackPageVisit(pageName, categoryData, locale, siteMerchandisingItems, publicRuntimeConfig, gender)
      optimizely && trackPageOptimizely(optimizely, 'home')
    })
  }

  render() {
    const { theme, locale, router, children, contactDetails, pageTitle } = this.props
    const config = getConfig().publicRuntimeConfig
    const { seoConfig, isJsonMarkupOn } = config
    const pageProps = children[0]?.props
    const Layout = BaseLayout(getTheme({ locale }))
    const LdJson = JSON.stringify(getCampanyPagesLdJson(router, pageProps, seoConfig, contactDetails))

    return (
      <ThemeProvider theme={{ theme, direction: setThemeDirection(locale) }}>
        <>
          {isJsonMarkupOn && (
            <Helmet>
              <script type="application/ld+json" key="company-page-jsonld">
                {LdJson}
              </script>
            </Helmet>
          )}
          <Layout>
            <Container>
              {pageTitle && <Title>{pageTitle}</Title>}
              {this.props.children}
            </Container>
          </Layout>
        </>
      </ThemeProvider>
    )
  }
}

export default withRouter(withTheme(withOptimizely(HomepageLayout)))
